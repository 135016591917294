<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					ZT8669 Desktop Smart Terminal
				</div>
				<div class="sub-text">
					<div class="sub-icon">
						<img src="../../assets/images/prods/zt8669/yh.png" />
						<span>Government affairs</span>
					</div>
					<div class="sub-icon">
						<img src="../../assets/images/prods/zt8669/yl.png" />
						<span>Bank</span>
					</div>
					<div class="sub-icon">
						<img src="../../assets/images/prods/zt8669/zw.png" />
						<span>Medical</span>
					</div>
					<div class="sub-icon">
						<img src="../../assets/images/prods/zt8669/jt.png" />
						<span>Traffic</span>
					</div>
				</div>
			</div>
		</div>
		<div class="part-2">
			<img src="../../assets/images/prods/zt8669/icon1.png" />
			<img src="../../assets/images/prods/zt8669/icon2.png" />
			<img src="../../assets/images/prods/zt8669/icon3.png" />
		</div>
		<div class="part-3 part-3-1">
			<div class="part-3-main">Modular design <br>
				& Flexible Customization</div>
			<div class="part-3-sub">GAS & Water& Electricity
				module extendable</div>
		</div>
		<div class="part-3 part-3-2">
			<h1>Powerful CPU & Excellent visual</h1>
			<h3>High-performance octa-core CPU, large touch screen</h3>
		</div>
		<div class="part-3 part-3-3">
			<h1>Variable size, Free choice</h1>
			<h3>Free choice of size</h3>
		</div>
		<div class="part-3 part-3-4">
			<h1>Complete functions and simple operation</h1>
		</div>
		<div class="part-4">
			<div class="part-4-head">
				<h1>Multi payments</h1>
				<h3>Online and offline integration to meet full-featured payment</h3>
			</div>
			<div class="part-4-show">
				<div class="screen">
					<div class="col-1">
						<div class="scence">
							<label>Convenience Store</label>
							<img src="../../assets/images/prods/zt8669/ps1.png" />
						</div>
					</div>
					<div class="col-2">
						<div class="scence">
							<label>Supermarket</label>
							<img src="../../assets/images/prods/zt8669/ps2.png" />
						</div>
						<div class="scence">
							<label>Bank</label>
							<img src="../../assets/images/prods/zt8669/ps3.png" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<ProdSpec :data="zt8669.data" />

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
import { zt8669 } from '@/views/products/spec/spec_en';
	import ProdSpec from "@/components/ProdSpec.vue";
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'ZT8669',
		components: {
			PageBot,
			PageNav,
			ProdSpec
		},
		created() {},
		watch: {

		},

		mounted() {

		},
		data() {
			return {
				avtive_nav: "",
				screenWidth: null,
				list_status: true,
				avtive_img: 0,
				zt8669
			}
		},
		methods: {}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 100vh;
		max-height: 950px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/prods/zt8669/bg1_en.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中

		.page_1_center_box {
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			color: #FFFFFF;

			.cen-text {
				margin-top: 25vh;
				margin-left: 20%;
				// background-color: aqua;
				font-size: 50px;
				font-weight: 600;
				width: 500px;
			}

			.sub-text {
				margin-left: 15%;
				margin-top: 1vh;
				height: 300px;
				// font-size: 20px;
				display: flex;
				justify-content: flex-start;

				.sub-icon {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 150px;
					margin-top: 20px;

					img {
						height: 70px;
						width: 70px;
					}

					span {
						margin-top: 10px;
					}
				}
			}
		}
	}

	.part-2 {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;

		img {
			margin-right: 15px;
		}
	}

	.part-3 {
		height: 90vh;
		background-size: 100% 100%;
		position: relative;

		div {
			color: #666;
		}

		.part-3-main {
			font-size: 55px;
			position: absolute;
			left: 12%;
			top: 23%;
		}

		.part-3-sub {
			font-size: 28px;
			position: absolute;
			left: 12%;
			top: 46%;
		}
	}

	.part-3-1 {
		background-image: url("../../assets/images/prods/zt8669/bg2.png");
		background-position: 100px 0;
	}

	.part-3-2 {
		background-image: url('../../assets/images/prods/zt8669/bg3.png');

		h1 {
			color: #fff;
			font-size: 55px;
			text-align: center;
			padding-top: 60px;
			padding-bottom: 20px;
			font-weight: 400;
		}

		h3 {
			color: #fff;
			font-size: 24px;
			text-align: center;
			font-weight: 400;
		}
	}

	.part-3-3 {
		background-image: url('../../assets/images/prods/zt8669/bg4_en.png');

		h1 {
			color: #666;
			font-size: 55px;
			text-align: center;
			padding-top: 40px;
			padding-bottom: 20px;
			font-weight: 400;
		}

		h3 {
			color: #666;
			font-size: 24px;
			text-align: center;
			font-weight: 400;
		}
	}

	.part-3-4 {
		background-image: url('../../assets/images/prods/zt8669/bg5_en.png');

		h1 {
			color: #fff;
			font-size: 55px;
			text-align: center;
			padding-top: 40px;
			padding-bottom: 25px;
			font-weight: 400;
		}
	}

	.part-4 {
		.part-4-head {
			margin: 90px 0;

			h1 {
				font-size: 55px;
				text-align: center;
			}

			h3 {
				font-size: 20px;
				font-weight: 400;
				text-align: center;
				margin-top: 10px;
			}
		}

		.part-4-show {
			background-image: url("../../assets/images/prods/psbg.png");
			background-size: 100% 100%;

			.screen {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 130px;
				height: 800px;

				.col-1 {
					height: 100%;
					margin-right: 20px;

					.scence {
						height: 100%;

						img {
							height: 100%;
							width: 100%;
						}
					}
				}

				.col-2 {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.scence {
						height: 380px;
						width: 600px;

						img {
							height: 100%;
							width: 100%;
						}
					}
				}

				.scence {
					position: relative;

					label {
						position: absolute;
						bottom: 0;
						background-color: rgba(0, 0, 0, .9);
						height: 75px;
						color: #fff;
						width: 100%;
						font-size: 27px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

			}
		}
	}

	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
</style>